.card {
    /* z-index: 9999; */
    display: grid;
    /* grid-column-start: 2; */
    /* grid-row-start: 4; */
    -ms-grid-row-align: inherit;
    /* border: 2px solid red; */
    background-color: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    width: 77%;
    border-radius: 3px;
    height: 79px;
    margin-top: 25px;
    grid-template-rows: 60% 40%;
     grid-template-columns: 11% 34% 11% 8% 13% 2% 23%;
  }
  
.card .tags{
    grid-row-start: 2;
    grid-column-start: 2;
}
.card .tags h4{
    width: 45px;
    background-color: #E25B32;
    font-size: 11px;
    color: white;
    margin-top: 6px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    text-align: center;
}
.card .tags2{
    grid-row-start: 2;
    grid-column-start: 2;
}
.card .tags2 h4{
    width: 45px;
    background-color: #E25B32;
    font-size: 11px;
    color: white;
    margin-top: 6px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 67px;
    text-align: center;
   grid-template-columns: 11% 34% 11% 8% 13% 2% 23%;

}
.card h1{

    display: inline;
    background-color: #F2F2F2;
}
.card img{
    margin-left: 14px;
    height: 52px;
    margin-top: 13px;
}
.card .star-image img{
    height: 20px;
    margin-top: 25px;

}
.card button{
    height: 32px;
    margin-top: 21px;
    margin-left: 45px;
    width: 127px;
    background-color: #FF6738;
    color: white;
    letter-spacing: 2px;
}
