.label{
    /* background-color: black; */
    display: grid;
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-gap: 30px;
    height: 100%;
    grid-template-columns:  63% 11% 21%;
    /* margin-left: 30px; */
    margin-right: 18px;
    /* border: 2px solid red; */
}
.label h5{
    margin-top:16px;
    margin-left: 10px;
    margin-bottom: 0px;
    /* color: blue; */
    /* padding: 0px 0px 0px 0px; */
}
.label img{
    border: 2px solid mediumvioletred;
    height: 15px;
    margin-top: -31px;
    margin-bottom: 46px;
    padding-bottom: -18px;
    margin-left: 100%;
    border: 2px solid;
    background: #FF6736;
    padding: 5px;
    color: #FF6736;

}
.label .label-dropdown select{
    width: 155px;
    height: 32px;
    margin-top: 12px;
}
.label input[type="text"]{
    width: 223px;
    height: 26px;
    margin-top: 11px;
} 
.label{
    /* background-color: black; */
    display: grid;
    grid-row-start: 3;
    grid-column-start: 2;
    grid-column-gap: 30px;
    height: 100%;
    grid-template-columns: 62% 11% 19% 3%;
    /* margin-left: 30px; */
    margin-right: 18px;
    /* border: 2px solid red; */
}
.label h5{
    margin-top:16px;
    margin-left: 10px;
    margin-bottom: 0px;
    /* color: blue; */
    /* padding: 0px 0px 0px 0px; */
}

.label .label-dropdown select{
    width: 155px;
    height: 32px;
    margin-top: 12px;
}
.label input[type="text"]{
    width: 223px;
    height: 26px;
    margin-top: 11px;
} 
