.d1{
    background-color: black;
    display: grid;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-gap: 30px;
    height: 100%;
    grid-template-columns: 55% 43%;
    /* margin-left: 30px; */
    margin-right: 18px;
    /* border: 2px solid red; */
    height: 83px;
}
.d1 img{
    height: 80px;
}
.d1 h5{
    color: white;
    /* height: 80px; */
    margin-left: 21px;
    /* margin-bottom: 3px; */
    margin-top: 13px;

    /* height: 80px; */
}