.basket{
    display: grid;
    padding-left: 100px;
}
.basket h1{
    border: 2px solid red;
    padding-left: 100px;
}
.basket .empty{

    font-size: 13px;
    margin-top: 30px;
    opacity: 0.85;
    text-align: center;
}
h3{
    /* border: 2px solid; */
    padding-left: 23px;
    font-weight: 500;
    
}