.header{
    /* border: 2px solid red; */
    position: fixed;
    top: 0;
    width: 100%;
    margin-top: 0px;
    display: grid;
    grid-template-columns: 15% 62% 7% 10% 3% 3%;
    height: 51px;
  
    background: #F5F5F5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
  }

  .header .logo1 img{
    padding-left: 0px;
    height: 41px;
    width: 109px;
    top: 10px;
    left: 24px;
    width: 128px;
    height: 41px;
    padding-left: 30px;
    padding-top: 5px;
    
      /* border  : 2px solid #F4F4F5;  */
  }
  h5{
    padding-top: 0px;
  }
  .header .im{    padding-top: 20px;
  }
 .header .course h4{
  padding-bottom: 8px;
  margin-bottom: 0px;
  margin-top: 18px;
 }
 .header .wish h5{
  padding-bottom: 10px;
  opacity: 0.5;
  font-weight: 500;
  margin-top: 20px;
}