.logo img{
    padding-left: 0px;
    height: 41px;
    width: 48px;
}

.logo h4{
    padding-left: 68px;
    padding-bottom: 0px;
    margin-bottom: -25px;
    font-size: 14px;
    margin-top: -48px;
}
.logo span{
    color: white;
}
.logo hr{
    margin-top: 49px;
}
