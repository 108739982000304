html
{
   
    font-family: 'Montserrat', sans-serif;
    padding: 0px;
    margin-top:0px;

    background-color:white;
    font-size: 1rem;
    scroll-padding-top: var(--top-spacing);
    font: 18px/1.4 system-ui; 
}
.section-container
   { height: 63vw;
    margin:auto;
    display: grid;
    grid-template-columns: 9% 82%;
    grid-template-rows: 6% 9% 4% 12% 12% 12% 12% 12% 12%;
}
.section-container .carrds{
  display: grid;
 
  grid-column-start: 2;
  grid-row-start: 4;
}
.section-container .basket{
    padding-top: 48x;
    display: grid;
    display: grid;
    grid-template-rows: 10% 16% 16% 16% 16% 16% 16% 16% 16%;
    padding-left: 82%;
    margin-top: -25%;
    padding-left: 10px;
    margin-top: -49%;
        height: auto;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    width: 18%;
    margin-top: -48%;
    margin-left: 80%;
}

.section-container .display{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-start: 2;
  grid-row-start: 4;
}
